<template>
  <section class="amenities" id="ammenities">
    <header>
      <h3>{{ $t('amenities') }}</h3>
      <p class="show-all" @click="expandedAmenities = !expandedAmenities">
        {{ $t('showAll') }}
        <span v-if="expandedAmenities"><img src="@/assets/hotel-detail/ic_button_deactive_dropdown_active_down.svg" alt=""></span>
        <span v-else><img src="@/assets/hotel-detail/ic_button_deactive_dropdown_active.svg" alt=""></span>
      </p>
    </header>
    <ul class="list">
      <li v-for="(amenity, index) in amenities.slice(0, howManyAmenitiesToDisplay)" :key="`amenity-index-${index}`">
        <img :src="getAmenityIcon(amenity)" alt="">
        <p>{{amenity}}</p>
      </li>
    </ul>
  </section>
</template>

<script>
import { getAmenityIconHelper } from '@/helpers/amenities'

export default {
  props: {
    amenities: Array
  },
  data: function () {
    return {
      expandedAmenities: false
    }
  },
  computed: {
    howManyAmenitiesToDisplay: function () {
      return this.expandedAmenities ? 4 : undefined
    }
  },
  methods: {
    getAmenityIcon (amenity) {
      return getAmenityIconHelper(amenity)
    }
  }
}
</script>

<style lang="scss" scoped>

.amenities {
  margin: 0;
  padding-top: 60px;

  header {
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 26px;

    h3 {
      margin: 0;
      font-size: 23px;
    }

    p.show-all {
      font-weight: 600;
      font-size: 14px;
      align-self: center;

      &:hover {
        text-decoration: underline;
      }

      span {
        margin-left: 5px;
      }
    }
  }

  ul.list {
    margin: 0 20px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    li {
      font-size: 15px;
      color: $secondary-black;
      flex-basis: 50%;
      margin-bottom: 30px;
      display: flex;

      p {
        align-self: center;
      }
    }
  }
}

@media (min-width: 768px) {
  .amenities {
    header {
      justify-content: flex-start;
      margin: 0 0 40px 0;

      h3 {
        font-size: 42px;
        margin-right: 40px;
      }

      p.show-all {
        font-size: 17px;
      }
    }

    ul.list {
      margin: 0;

      li {
        flex-basis: 25%;
      }
    }
  }
}

</style>

<template>
  <section class="location" id="location">
    <h3>{{ $t('location') }}</h3>
    <hotel-map
      :hotels="[hotel]"
      :initialZoom="12"
      :center="center"
      :singleHotelMode="true"
      ></hotel-map>
    <a :href="`https://www.google.com/maps/dir/?api=1&origin=${hotel.location.coordinates[1]},${hotel.location.coordinates[0]}&destination=${congressLat},${congressLng}`" target="_blank" rel="noopener noreferrer" >{{ $t('getThere') }}</a>
  </section>
</template>

<script>
import HotelMap from '@/components/list-page/HotelMap'
import { middlePoint } from '@/helpers/midpointCoordinates'
import { mapGetters } from 'vuex'

export default {
  components: {
    HotelMap
  },
  props: {
    hotel: Object
  },
  computed: {
    ...mapGetters([
      'congressLat',
      'congressLng'
    ])
  },
  data: function () {
    return {
      center: null
    }
  },
  created () {
    this.center = middlePoint(this.hotel.location.coordinates[1], this.hotel.location.coordinates[0], this.congressLat, this.congressLng)
  }

}
</script>

<style lang="scss" scoped>

.location {
  margin: 0;
  padding-bottom: 40px;
  padding-top: 60px;
  border-bottom: 1px solid #E9E9E9;

  h3 {
    font-size: 20px;
    margin: 0 20px 20px;
  }

  a {
    margin: 0 20px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: $secondary-black;

    &::before {
      content: url('../../assets/hotel-detail/ic_detail_find_hotel.svg');
      margin-right: 10px;
      vertical-align: sub;
    }
  }

  .map-container {
    height: 470px;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .location {
    padding-bottom: 80px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 15% 85%;
    grid-template-areas:
    "title getToHotel"
    "map map";

    h3 {
      margin: 0;
      font-size: 42px;
      grid-area: title;
    }

    a {
      grid-area: getToHotel;
      margin: 0 0 5px;
      align-self: end;
      justify-self: end;
    }

    .map-container {
      grid-area: map;
      padding-top: 0;
    }
  }
}

</style>

<template>
  <section class="similar-hotel-card">
    <img class="hotel-image" :src="hotel.images[0]" alt="">
    <div class="stars">
      <img v-for="(star, i) in hotel.stars" class="star" :key="`stars-hotel-card-${hotel.id}-${i}`" src="@/assets/ic_hotel_card_star_1.svg" :alt="`one star of ${hotel.stars}`">
    </div>
    <h3>{{hotel.name}}</h3>
    <div class="distances">
      <p class="center">
        {{ hotel.km_to_center }} {{ $t('kmFromCenter') }}
      </p>
      <p class="congress">
        {{ hotel.km_to_congress }} {{ $t('kmFromCongress') }}
      </p>
    </div>
    <div class="price-link">
      <div class="price">
        <div v-if="dataHotelsIsReady">
          <p>{{hotel.average_day_price.toFixed(2)}}€</p>
          <p class="per-night">{{ $t('averagePriceNight') }}</p>
        </div>
      </div>
      <router-link :to="{ name: 'hotel-detail-page', params: { id: hotel.id } }">{{ $t('moreInfo') }}</router-link>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    hotel: Object
  },
  computed: {
    ...mapGetters([
      'cityCenterLat',
      'cityCenterLng',
      'congressLat',
      'congressLng',
      'dataHotelsIsReady'
    ])
  }
}
</script>

<style lang="scss" scoped>
.similar-hotel-card{
  margin: 0;
  height: 100%;

  &:hover {
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.25);
  }

  .hotel-image {
    width: 100%;
    max-height: 145px;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 10px;
  }

  .stars {
    margin: 0 20px;
    margin-bottom: 7px;
  }

  h3 {
    margin: 0 20px 5px;
    font-size: 20px;
    color: $secondary-black;
  }

  .distances {
    margin: 0 20px;
    font-size: 14px;
    border-bottom: 1px solid #E9E9E9;

    .center::before {
        content: var(--icon-walking-distance)
    }

    .congress {
      margin-bottom: 10px;
    }

    .congress::before {
      content: var(--icon-walking-distance);
      opacity: 0;
    }
  }

  .price-link {
    padding-top: 15px;
    margin: 0 20px;
    display: flex;
    justify-content: space-between;

    .price {
      align-self: center;

      p:first-child {
        font-weight: bold;
        font-size: 30px;
      }

      p.per-night {
        font-size: 14px;
      }
    }

    a {
      align-self: flex-end;
      display: block;
      text-align: right;
      color: $primary-black;
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;

      &::after {
        content: url('../../assets/hotel-detail/ic_button_deactive_dropdown_active_right.svg')
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

</style>

<template>
  <spinner :visible="spinnerVisible"></spinner>
  <section class="hotel-detail">
    <nav-bar :class="{ show: isActive }"></nav-bar>
    <div class="container-detail">
      <div class="links-wrapper">
        <links></links>
      </div>
      <section class="hotel-detail-header">
        <section class="hotel-info">
          <a class="go-back" @click="goBack"><p>&lt; {{ $t('goBack') }}</p></a>
          <detail-header :hotel="hotel" :btnActive="true"></detail-header>
        </section>
      <div class="images-nav-bar">
        <gallery :images="hotel.images"></gallery>
        <hotel-nav-bar></hotel-nav-bar>
      </div>
      </section>
      <detail-summary :hotel="hotel"></detail-summary>
      <section class="room-info" id="choose-room">
        <h2>{{ $t('roomInformation') }}</h2>
        <room-card :hotel="hotel"></room-card>
      </section>
      <location-detail :hotel="hotel"></location-detail>
      <detail-info :hotel="hotel"></detail-info>
      <similar-hotels></similar-hotels>
    </div>
    <contact-footer :showFooter="true"></contact-footer>
    <legal-footer :showFooter="true"></legal-footer>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

import TitleSetter from "@/components/TitleSetter";
import NavBar from "@/components/Navbar";
import Links from "@/components/Links";
import DetailHeader from "../components/hotel-detail-page/DetailHeader";
import DetailSummary from "../components/hotel-detail-page/DetailSummary";
import RoomCard from '@/components/hotel-detail-page/RoomCard'
import LocationDetail from "@/components/hotel-detail-page/LocationDetail";
import DetailInfo from "../components/hotel-detail-page/DetailInfo";
import SimilarHotels from "../components/hotel-detail-page/SimilarHotels";
import ContactFooter from "@/components/landing-page/ContactFooter";
import LegalFooter from "@/components/landing-page/LegalFooter";
import HotelNavBar from '@/components/hotel-detail-page/HotelNavBar'
import Gallery from '@/components/hotel-detail-page/Gallery'
import Spinner from "@/components/Spinner";

export default {
  name: "detail",
  components: {
    TitleSetter,
    NavBar,
    Links,
    DetailHeader,
    DetailSummary,
    RoomCard,
    LocationDetail,
    DetailInfo,
    SimilarHotels,
    ContactFooter,
    LegalFooter,
    HotelNavBar,
    Gallery,
    Spinner,
  },

  data: () => {
    return {
      hotelId: null,
      isActive: true,
      spinnerVisible: false,
    };
  },
  computed: {
    hotel() {
      if (this.$route.params.id) {
        if (this.dataHotelsIsReady) {
          let dynamicHotelData = this.$store.getters.hotel(Number(this.$route.params.id))
          if (dynamicHotelData)
            return dynamicHotelData
          else
            return this.$store.getters.staticHotel(Number(this.$route.params.id));
        }
        else if (this.dataIsReady) {
          return this.$store.getters.staticHotel(Number(this.$route.params.id));
        }
      }
      return {}
    },
    ...mapGetters(["congressName", "dataIsReady", "dataHotelsIsReady", "hotels"]),
  },
  watch: {
    dataHotelsIsReady(newValue) {
      if(newValue) {
        this.spinnerVisible = false
      }
    }
  },
  mounted() {
    if(!this.dataHotelsIsReady) {
        this.spinnerVisible = true
    }
    this.$store.commit("SET_HOTEL_ID", this.$route.params.id)
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'list',
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.go-back {
  text-decoration: none;
  color: #2c3e50;
  font-weight: bold;
  cursor: pointer;
}

.hotel-detail {
  height: 100%;
  text-align: left;
  color: $secondary-black;
  margin: 0;

  .links-wrapper {
    padding-top: 60px;
    margin-bottom: 40px;
    overflow: scroll;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);

    .links {
      padding-left: 0;

      a {
        font-weight: bold;
        color: #747272;
        font-size: 14px;
      }
    }
  }
}

.room-info {
  margin: 0;
  border-bottom: 1px solid #E9E9E9;

  h2 {
    font-size: 20px;
    margin: 0 20px 20px 20px;
    color: $primary-black;
  }

  .filters {
    margin: 0 20px 40px 20px;

    button {
      margin-right: 20px;
      background-color: $primary-black;
      border: none;
      border-radius: 5px;
      padding: 10px 10px 9px 10px;
      outline: none;
    }

  }
}

@media (min-width: 768px) {
  .room-info {
    padding-bottom: 80px;
    padding-top: 60px;

    h2 {
      font-size: 42px;
      margin-left: 0;
    }

    .filters {
      margin-left: 0;
    }
  }
  .hotel-detail {
    padding-top: 140px;
  }
  .container-detail {
    max-width: 1200px;
    margin: 0 auto;

    .links-wrapper {
      display: none;
    }
  }
}
</style>

<template>
  <section class="similar-hotels" id="similar">
    <h3>{{ $t('similarHotelsMatch') }}</h3>
    <ul>
      <li v-for="hotel in recommendedHotels" :key="`similar-hotel-${hotel.originalId}`">
        <similar-hotel-card :hotel="hotel"></similar-hotel-card>
      </li>
    </ul>
  </section>
</template>

<script>
import SimilarHotelCard from '@/components/hotel-detail-page/SimilarHotelCard'

import { mapGetters } from 'vuex'

export default {
  components: { SimilarHotelCard },
  computed: {
    ...mapGetters([
      'recommendedHotels'
    ])
  }
}
</script>

<style lang="scss" scoped>

.similar-hotels {
  margin: 0;
  padding-bottom: 40px;
  padding-top: 60px;
  border-bottom: 1px solid #E9E9E9;

  h3 {
    margin: 0 20px;
    font-size: 20px;
    color: $primary-black;
  }

  ul {
    padding: 0;
    list-style: none;
    margin-left: 10px;
    overflow: auto;
    white-space: nowrap;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    li {
      height: 355px;
      width: 260px;
      border: 1px solid #E9E9E9;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
}

@media (min-width: 768px) {
  .similar-hotels {
    padding-bottom: 80px;
    margin-bottom: 160px;

    h3 {
      margin: 0 0 20px;
      font-size: 42px;
    }

    ul {
      margin: 0;
      overflow: unset;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;

      li {
        width: unset;
      }
    }
  }
}
</style>

<template>
  <section class="other-info">
    <detail-amenities :amenities="hotel.amenities" v-if="hotel.amenities"></detail-amenities>
  </section>
</template>

<script>
import DetailAmenities from '../../components/hotel-detail-page/DetailAmenities'

export default {
  components: {
    DetailAmenities,
  },
  props: {
    hotel: Object
  }

}
</script>

<style lang="scss" scoped>
.other-info {
  margin: 0;
}

@media (min-width: 768px) {
  .other-info {
    padding-bottom: 50px;
    border-bottom: 1px solid #E9E9E9;

    .terms-know {
      display: flex;
    }
  }
}

</style>

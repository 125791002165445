const amenities = {
  'Air conditioning': require('@/assets/hotel-detail/ic_services_airconditioning.svg'),
  'Airport  shuttle  service': require('@/assets/hotel-detail/ic_services_airportshuttle.svg'),
  'Babysitting  service': require('@/assets/hotel-detail/ic_services_babysitting.svg'),
  'Bar': require('@/assets/hotel-detail/ic_services_bar.svg'),
  'beachaccess': require('@/assets/hotel-detail/ic_services_beachaccess.svg'),
  'Bike  rental': require('@/assets/hotel-detail/ic_services_bikerental.svg'),
  'Breakfast  included': require('@/assets/hotel-detail/ic_services_breakfast.svg'),
  'Cafeteria': require('@/assets/hotel-detail/ic_services_cafeteria.svg'),
  'Car  rental  service': require('@/assets/hotel-detail/ic_services_carrental.svg'),
  'Cleaner  service (daily)': require('@/assets/hotel-detail/ic_services_cleaner.svg'),
  'Disability  access': require('@/assets/hotel-detail/ic_services_disability.svg'),
  'Family Rooms': require('@/assets/hotel-detail/ic_services_family.svg'),
  'Fitness center': require('@/assets/hotel-detail/ic_services_fitnesscenter.svg'),
  'Free Wifi': require('@/assets/hotel-detail/ic_services_freewifi.svg'),
  'Heating': require('@/assets/hotel-detail/ic_services_heating.svg'),
  'Laundry  service': require('@/assets/hotel-detail/ic_services_laundryservice.svg'),
  'Library': require('@/assets/hotel-detail/ic_services_library.svg'),
  'Meeting room': require('@/assets/hotel-detail/ic_services_meetingroom.svg'),
  'Non smoking  rooms': require('@/assets/hotel-detail/ic_services_nonsmooking.svg'),
  'Parking': require('@/assets/hotel-detail/ic_services_parking.svg'),
  'Pets allowed': require('@/assets/hotel-detail/ic_services_petspermitted.svg'),
  'Restaurant': require('@/assets/hotel-detail/ic_services_restaurant.svg'),
  'Rooftop terrace': require('@/assets/hotel-detail/ic_services_rooftopterrace.svg'),
  'Room service': require('@/assets/hotel-detail/ic_services_roomservice.svg'),
  'Safe': require('@/assets/hotel-detail/ic_services_safe.svg'),
  'Sauna': require('@/assets/hotel-detail/ic_services_sauna.svg'),
  'Smoking Room': require('@/assets/hotel-detail/ic_services_smokingroom.svg'),
  'Smoking zone': require('@/assets/hotel-detail/ic_services_smokingzone.svg'),
  'Spa': require('@/assets/hotel-detail/ic_services_spa.svg'),
  'Spa/Health center': require('@/assets/hotel-detail/ic_services_spahealthcenter.svg'),
  'Suite': require('@/assets/hotel-detail/ic_services_suite.svg'),
  'Swimming Pool': require('@/assets/hotel-detail/ic_services_swimmingpool.svg'),
  'Touristic information': require('@/assets/hotel-detail/ic_services_touristicinfo.svg'),
  'Wifi (paid)': require('@/assets/hotel-detail/ic_services_wifi.svg')
}

export const getAmenityIconHelper = (amenity) => {
  return amenities[amenity]
}

/*
 * Find midpoint between two coordinates points
 * Source : http://www.movable-type.co.uk/scripts/latlong.html
 */

const toRad = (deg) => {
  return deg * Math.PI / 180
}

const toDeg = (rad) => {
  return rad * (180 / Math.PI)
}

// -- Define middle point function
export const middlePoint = (lat1, lng1, lat2, lng2) => {
  // -- Longitude difference
  let dLng = toRad(lng2 - lng1)

  // -- Convert to radians
  lat1 = toRad(lat1)
  lat2 = toRad(lat2)
  lng1 = toRad(lng1)

  let bX = Math.cos(lat2) * Math.cos(dLng)
  let bY = Math.cos(lat2) * Math.sin(dLng)
  let lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY))
  let lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX)

  // -- Return result
  return { lng: toDeg(lng3), lat: toDeg(lat3) }
}

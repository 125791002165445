<template>
  <section class="hotel-summary" id="description">
    <div class="description">
      <h1 class="title">{{ $t('description') }}</h1>
      <p v-html="hotel.description"></p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    hotel: Object
  }
}
</script>

<style lang="scss" scoped>
.hotel-summary {
  margin: 0;
  padding-bottom: 40px;
  padding-top: 60px;

  .description {
    margin: 0;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #E9E9E9;

    h1 {
      margin: 0 20px;
      font-size: 20px;
      margin-bottom: 14px;
    }

    p {
      margin: 0 20px;
      font-size: 15px;
      color: $secondary-black;
      line-height: 22px;
      text-align: justify;
      white-space: pre-line;
    }
  }
}

@media (min-width: 768px) {
  .hotel-summary {
    display: flex;
    padding-bottom: 80px;
    padding-top: 80px;
    border-bottom: 1px solid #E9E9E9;

    .description {
      margin-right: 140px;
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;

      .title {
        font-size: 42px;
      }

      p {
        text-align: left;
        font-size: 18px;
      }
    }
  }
}

</style>
